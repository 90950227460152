import React from 'react';

import Layout from '../../../components/layout';
import Container from '../../../components/container';
import ResidenceDetails from '../../../components/residence-details';

import Real1 from '../../../images/work/05_CARMEL_PRICE/PHOTOS/PR_01.jpg';
import Real1_0 from '../../../images/work/05_CARMEL_PRICE/PHOTOS/PR_01_0.jpg';
import Real2 from '../../../images/work/05_CARMEL_PRICE/PHOTOS/PR_02.jpg';
import Real3 from '../../../images/work/05_CARMEL_PRICE/PHOTOS/PR_03.jpg';
import Real4 from '../../../images/work/05_CARMEL_PRICE/PHOTOS/PR_04.jpg';
import Real5 from '../../../images/work/05_CARMEL_PRICE/PHOTOS/PR_05.jpg';
import Real6 from '../../../images/work/05_CARMEL_PRICE/PHOTOS/PR_06.jpg';
import Real7 from '../../../images/work/05_CARMEL_PRICE/PHOTOS/PR_07.jpg';
import Real1s from '../../../images/work/05_CARMEL_PRICE/PHOTOS/450x280/PR-SM_01.jpg';
import Real1s_0 from '../../../images/work/05_CARMEL_PRICE/PHOTOS/450x280/PR-SM_01_0.jpg';
import Real2s from '../../../images/work/05_CARMEL_PRICE/PHOTOS/450x280/PR-SM_02.jpg';
import Real3s from '../../../images/work/05_CARMEL_PRICE/PHOTOS/450x280/PR-SM_03.jpg';
import Real4s from '../../../images/work/05_CARMEL_PRICE/PHOTOS/450x280/PR-SM_04.jpg';
import Real5s from '../../../images/work/05_CARMEL_PRICE/PHOTOS/450x280/PR-SM_05.jpg';
import Real6s from '../../../images/work/05_CARMEL_PRICE/PHOTOS/450x280/PR-SM_06.jpg';
import Real7s from '../../../images/work/05_CARMEL_PRICE/PHOTOS/450x280/PR-SM_07.jpg';
import Process1 from '../../../images/work/05_CARMEL_PRICE/PROCESS/PRI_01.jpg';
import Process2 from '../../../images/work/05_CARMEL_PRICE/PROCESS/PRI_02.jpg';
import Process3 from '../../../images/work/05_CARMEL_PRICE/PROCESS/PRI_03.jpg';
import Process4 from '../../../images/work/05_CARMEL_PRICE/PROCESS/PRI_04.jpg';
import Process5 from '../../../images/work/05_CARMEL_PRICE/PROCESS/PRI_05.jpg';
import Process6 from '../../../images/work/05_CARMEL_PRICE/PROCESS/PRI_06.jpg';
import Process7 from '../../../images/work/05_CARMEL_PRICE/PROCESS/PRI_07.jpg';
import Process1s from '../../../images/work/05_CARMEL_PRICE/PROCESS/450x280/PRI-SM_01.jpg';
import Process2s from '../../../images/work/05_CARMEL_PRICE/PROCESS/450x280/PRI-SM_02.jpg';
import Process3s from '../../../images/work/05_CARMEL_PRICE/PROCESS/450x280/PRI-SM_03.jpg';
import Process4s from '../../../images/work/05_CARMEL_PRICE/PROCESS/450x280/PRI-SM_04.jpg';
import Process5s from '../../../images/work/05_CARMEL_PRICE/PROCESS/450x280/PRI-SM_05.jpg';
import Process6s from '../../../images/work/05_CARMEL_PRICE/PROCESS/450x280/PRI-SM_06.jpg';
import Process7s from '../../../images/work/05_CARMEL_PRICE/PROCESS/450x280/PRI-SM_07.jpg';

const Residence05 = () => (
  <Layout>
    <Container>
      <ResidenceDetails
        residenceTitle="MUSICIAN'S RETREAT"
        residenceLocation="CARMEL, CALIFORNIA"
        infoList={[
          'New 675 SF Music Room built on a former garage foundation.',
          'Design - 2017',
          'Construction - 2017',
          'General Contractor: McNamee Construction, Inc',
          'Structural Engineer: Structures Engineering, Inc',
          'Photos: Anatoly Ostretsov, Philip Price',
        ]}
        testimonialList={[
          'I am extremely grateful to Anatoly Ostretsov for the beautiful Music Room he designed for us in Carmel. The design is both beautiful and very functional. Although the space was slightly less than 700 square feet, he was able to nicely accommodate the multi-purpose nature of this room for music with concert grand piano, hospitality, wine tasting, media center and home office, with large windows overlooking Monterey Bay, in a way that is breath-taking.  Anatoly designed the cabinetry, office space, window sizing and placements to perfection!',
          'Anatoly was also very easy to work with, accommodating to my needs, and speedy in obtaining all necessary permits for construction. Anatoly’s expertise with building codes and local approval requirements was very helpful.',
          'I am happy to recommend him without reservation!',
          'Phil',
        ]}
        photos={[Real1, Real1_0, Real2, Real3, Real4, Real5, Real6, Real7]}
        photosSmall={[
          Real1s,
          Real1s_0,
          Real2s,
          Real3s,
          Real4s,
          Real5s,
          Real6s,
          Real7s,
        ]}
        processPhotos={[
          Process1,
          Process2,
          Process3,
          Process4,
          Process5,
          Process6,
          Process7,
        ]}
        processPhotosSmall={[
          Process1s,
          Process2s,
          Process3s,
          Process4s,
          Process5s,
          Process6s,
          Process7s,
        ]}
      />
    </Container>
  </Layout>
);

export default Residence05;
